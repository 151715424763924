<template>
  <div class="jobs p-8 mx-auto">
    <div class="space-y-6">
      <div class="flex justify-between">
        <div class="flex items-center">
          <svg
            @click="router.go(-1)"
            class="h-8 w-8 text-blue-600 hover:text-blue-700 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
            />
          </svg>
          <h1 class="text-3xl font-semibold ml-3">
            Jobs Map
          </h1>
        </div>

        <div class="flex gap-4">
          <div class="min-w-48 flex flex-col">
            <label
              for="crew_filter"
              class="block text-sm font-medium text-gray-600"
            >
              Crew
            </label>
            <select
              v-model="filters.crew"
              name="crew_filter"
              class="border rounded py-2 border-gray-200 text-lg"
            >
              <option> </option>
              <option v-for="crew in crewArray" :key="crew.id" :value="crew.id">
                {{ crew.crew_name }}
              </option>
            </select>
          </div>
          <div class="min-w-48 flex flex-col">
            <label
              for="date_filter"
              class="block text-sm font-medium text-gray-600"
              >Date</label
            >
            <select
              v-model="filters.date"
              class="border rounded py-2 border-gray-200 text-lg"
            >
              <option> </option>
              <option v-for="date in dateArray" :key="date" :value="date">
                {{ dateFromISO(date) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div id="map" ref="jobMap"></div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";
import useLuxon from "@/composables/useLuxon";

export default {
  props: ["user"],
  setup(props) {
    const isLoading = ref(true);
    const route = useRoute();
    const router = useRouter();

    const map = ref(null);
    const jobMap = ref(null);
    const markers = [];

    const crewArray = ref([]);
    const dateArray = ref([]);
    const filters = ref({
      date: "",
      crew: "",
    });

    const business_id = props.user.claims.business_id ?? "";

    const { error, documents: jobs } = getCollection(
      `businesses/${business_id}/jobs`
    );

    const { error: crews_err, documents: crews } = getCollection(
      `businesses/${business_id}/crews`
    );

    const filteredJobs = computed(() => {
      return jobs.value?.filter((job) => {
        if (filters.value.date && job.job_scheduled != filters.value.date) {
          return;
        }
        if (filters.value.crew && job.crew.id != filters.value.crew) {
          return;
        }
        return true;
      });
    });

    // const pinSymbol = (status) => {
    //   let color;
    //   switch (status) {
    //     case "pending":
    //       color = "#37b981";
    //       break;
    //     case "assigned":
    //       color = "#3b82f6";
    //       break;
    //     case "processed":
    //       color = "#8b5cf6";
    //       break;
    //     case "reviewed":
    //       color = "#ff5722";
    //       break;
    //     case "emailed":
    //       color = "#737373";
    //       break;
    //     case "accepted":
    //       color = "#37b981";
    //       break;
    //     default:
    //       color = "#ea4335";
    //       break;
    //   }
    //   status;
    //   return {
    //     path:
    //       "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
    //     fillColor: color,
    //     fillOpacity: 1,
    //     strokeColor: "#000",
    //     strokeWeight: 2,
    //     scale: 1,
    //   };
    // };

    watch([jobs, crews], () => {
      if (jobs.value && crews.value) {
        const crewSet = new Set();
        const dateSet = new Set();
        jobs.value.forEach((job) => {
          if (job?.crew.id.length > 1) {
            crewSet.add(job.crew.id ?? null);
          }
          if (job?.job_scheduled.length > 1) {
            dateSet.add(job.job_scheduled ?? null);
          }
        });
        crewSet.forEach((crew_id) => {
          crewArray.value.push({
            id: crew_id,
            crew_name: crews.value?.find(({ id }) => id === crew_id).crew_name,
          });
        });
        crewArray.value.sort((a, b) => (a.crew_name > b.crew_name ? 1 : -1));
        dateArray.value = Array.from(dateSet).sort();
        isLoading.value = false;
      }
    });

    const addInfoWindow = (marker, content) => {
      const infowindow = new window.google.maps.InfoWindow({
        content: content,
      });
      marker.addListener("click", () => {
        infowindow.open(map.value, marker);
      });
    };

    const clearMarkers = () => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
    };

    const initMap = () => {
      const mapOptions = {
        mapTypeId: "roadmap",
        mapTypeControl: false,
        zoom: 15,
        streetViewControl: false,
      };
      map.value = new window.google.maps.Map(jobMap.value, mapOptions);
    };

    onMounted(() => {
      initMap();
    });

    watch(filteredJobs, () => {
      const bounds = new window.google.maps.LatLngBounds();
      clearMarkers();

      filteredJobs.value?.forEach((job) => {
        const coords = new window.google.maps.LatLng(
          Number(job.location.location_coordinates.lat),
          Number(job.location.location_coordinates.long)
        );

        const marker = new window.google.maps.Marker({
          map: map.value,
          position: coords,
          // icon: pinSymbol(job.status),
        });

        markers.push(marker);

        addInfoWindow(
          marker,
          `<a href="/jobs/${job.id}">Job ${job.sequence_number}</a>
                <br>${job.location.location_address1}`
        );

        bounds.extend(coords);
        map.value.fitBounds(bounds);
      });
    });

    const { dateFromISO } = useLuxon();

    return {
      isLoading,
      route,
      router,
      clearMarkers,
      jobMap,
      error,
      jobs,
      crews_err,
      crews,
      dateArray,
      crewArray,
      dateFromISO,
      filters,
      filteredJobs,
    };
  },
};
</script>

<style scoped>
#map {
  height: 700px;
  width: 100%;
}
</style>
